import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "gatsby"

class SimpleSlider extends React.Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 0,
      speed: 2000,
      arrows: false,
      swipe: false,
      slidesToShow: 4,
      cssEase: "linear",
      pauseOnFocus: false,
      pauseOnHover: false,
    };

    return (
      <div>
        <Slider {...settings}>
          <div className="featureon">
            <Link to="/press" title="We’re famous!">
              <img
                src="https://assets.gofloaters.com/featureon/forbes.jpg"
                width="155"
                height="92"
                alt="Forbes"
              />
            </Link>
          </div>
          <div className="featureon">
            <Link to="/press" title="We’re famous!">
              <img
                src="https://assets.gofloaters.com/featureon/yourstory.jpg"
                width="155"
                height="91"
                alt="YourStory"
              />
            </Link>
          </div>
          <div className="featureon">
            <Link to="/press" title="We’re famous!">
              <img
                src="https://assets.gofloaters.com/featureon/entrepreneurindia.jpg"
                width="155"
                height="82"
                alt="Entrepreneur India"
              />
            </Link>
          </div>
          <div className="featureon">
            <Link to="/press" title="We’re famous!">
              <img
                src="https://assets.gofloaters.com/featureon/thehindu.jpg"
                width="155"
                height="95"
                alt="The Hindu"
              />
            </Link>
          </div>
          <div className="featureon">
            <Link to="/press" title="We’re famous!">
              <img
                src="https://assets.gofloaters.com/featureon/startup-story.jpg"
                width="155"
                height="92"
                alt="Startup Story"
              />
            </Link>
          </div>
          <div className="featureon">
            <Link to="/press" title="We’re famous!">
              <img
                src="https://assets.gofloaters.com/featureon/timesofindia.png"
                width="155"
                height="96"
                alt="Times of India"
              />
            </Link>
          </div>
        </Slider>
      </div>
    )
  }
}

export default SimpleSlider
