import React, { useEffect, useState, createRef, lazy, Suspense } from "react"
import { Helmet } from "react-helmet"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { Link, useStaticQuery } from "gatsby"
import GofloaterSearchForm from "../components/search/gofloaterssearch"
import "../styles/hybrid.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import SimpleSlider from "../components/slider"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/worqpass.svg"
import lottie from "lottie-web"
import VizSensor from "react-visibility-sensor"
import animation from "../animations/newhomeanimation.json"
import HomeBannerAlter from "../img/Home-Banner-Mobile.jpg"
import NewHomeSearch from "../components/search/newhomesearch"
import HybridReportImg from "../img/Report-Hybrid.jpg"
import "lazysizes"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import Img from "gatsby-image"

export default function Home() {
  const data = useStaticQuery(graphql`
    query BrandPagesQuery {
      allCities {
        totalCount
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
      cityCounts: allListings(
        filter: { operatorName: { regex: "/iKSANA Workspaces/" }, slug: { ne: "" } }
        sort: { fields: isFeatured, order: DESC }
      ) {
        totalCount
        edges {
          node {
            spaceCity
            OriginalName
            spaceAddress
            spaceType
            dayPassAvailable
          }
        }
        cityNameCount: distinct(field: spaceCity)
        spaceAddressCount: distinct(field: spaceAddress)
      }
      allListings(
        filter: { operatorName: { regex: "/iKSANA Workspaces/" } }
        sort: { fields: isFeatured, order: DESC }
      ) {
        totalCount
        edges {
          node {
            monthPassAvailable
            dayPassAvailable
            hourPassAvailable
            officeSpaceType
            purposesList
            spaceAddress
            spaceGFName
            OriginalName
            spaceCity
            spaceId
            spaceImage
            spaceTitle
            spaceDesc
            spaceType
            subType
            priceDay
            priceHr
            priceMonth
            spaceDisplayName
            Facility
            slug
            hasCovidSafeBadge
            online
            Rating
            pincode
            bookingcount
            isFeatured
            priceperdayPreDiscount
            priceperhrPreDiscount
          }
        }
      }
    }
  `)

  let animationContainer = createRef()

  console.log(data ,"full data")

  const cityCount = data.cityCounts
  const cityNameCount = data.cityCounts.cityNameCount

  const coworkingspaceCount = cityCount.edges.filter(
    list => list.node.spaceType === "Shared Workspace"
  )
  const meetingspaceCount = cityCount.edges.filter(
    list => list.node.spaceType === "Conference Room"
  )

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    }
  }, [])
  const CityImage = props => {
    const imageBucket = {
      coworkingPhotos: [],
      meetingRoomPhotos: [],
    }
    const getPhotoUrlByCity = cityName => {
      const photoObject = imageBucket.meetingRoomPhotos.find(
        photo => photo.city.toLowerCase() === cityName.toLowerCase()
      )

      return photoObject ? photoObject.photo : null
    }

    // Example usage:

    const photoUrl = getPhotoUrlByCity(
      props.name.toLowerCase().replace(" ", "-")
    )
    const localitynew = data.allListings.edges.filter(
      list =>
        list.node.spaceCity === props.name &&
        list.node.spaceType === props.spaceType
    )

    if (localitynew.length > 0) {
      return (
        <div className="space_img" title={props.name}>
          <img
            src={localitynew[0].node.spaceImage.replace(
              "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
              "https://cdn.app.gofloaters.com"
            )}
            alt={props.name}
            title={props.name}
          />
        </div>
      )
    }
    return ""
  }

  return (
    <div>
      <SEOHeader
        title="GoFloaters: Book Coworking Spaces for work and meetings"
        description="Looking for coworking, shared office, or meeting space? Book spaces by the hour or by the day or monthly. Book instantly ⚡ from a range of workspaces across 40+ cities and save at least 25%."
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
      ></SEOHeader>
      <Helmet>
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org/",
          "@type": "Organization",
          "name": "GoFloaters",
          "url":"https://gofloaters.com/",
          "logo":"https://assets.gofloaters.com/icons/gofloaters_logo.png",
          "sameAs":["https://www.facebook.com/gofloatersindia", "https://www.instagram.com/gofloatersapp", "https://twitter.com/gofloaters", "https://www.youtube.com/channel/UCy_PdgFKsHuZY_hZ2r8gQvg","https://www.linkedin.com/company/gofloaters/"],
          "contactPoint":{
            "@type":"ContactPoint",
            "telephone":"+91 7338 7308 00",
            "contactType":"Customer Service"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.6",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Gofloaters App Users"
            }
          }
        }`}
        </script>
        <script type="application/ld+json">
          {` {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.gofloaters.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.gofloaters.com/sitesearch/?query={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }`}
        </script>
      </Helmet>
      <LayoutTeam>
        <Navigation />
        <div className="meetingSpaces">
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <h1
                    style={{
                      fontSize: "3em",
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: "75px 0 30px ",
                    }}
                  >
                    iKSANA Workspace{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <p
              style={{
                textAlign: "justify",
                padding: " 0 0px 40px ",
                fontSize: "18px",
              }}
              className="mb-4"
            >
              iKSANA Workspaces is revolutionizing the Indian workspace
              landscape, fostering a vibrant ecosystem for professionals of all
              stripes. Established in 2018, they've grown to encompass multiple
              locations across 2 key cities, empowering individuals and
              businesses to thrive. They are the leading coworking operator in
              Dehradun with 3 locations.
            </p>

            <div className="col-md-12">
              <div className="row">
                <br></br>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/brands/city.svg"
                          alt={cityNameCount.length + "Number of cities"}
                          className="lazyload w-100"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>{cityNameCount.length}</h4>
                        <p>Number of cities</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/brands/location.svg"
                          alt={cityCount.totalCount}
                          className="lazyload w-100"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4> {cityCount.totalCount}</h4>
                        <p>Number of locations</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/brands/coworking.svg"
                          alt={
                            coworkingspaceCount.length +
                            "Number of coworking day pass locations"
                          }
                          className="lazyload w-100"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>{coworkingspaceCount.length}</h4>
                        <p>Number of coworking day pass locations</p>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div className="col-md-3 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/brands/meeting.svg"
                          alt={
                            meetingspaceCount.length + "Number of meeting rooms"
                          }
                          className="lazyload w-100"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>{meetingspaceCount.length}</h4>
                        <p>Number of meeting rooms</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                style={{
                  padding: "20px 0 0px",
                  fontWeight: "bold",
                  color: "#303030",
                  lineHeight: "1",
                  fontSize: "32px",
                }}
              >
                Meeting Spaces
              </h2>
            </div>
          </div>
          <div className="row">
            {data.allCities.edges.map(city => {
              const localitynew = data.allListings.edges.filter(
                list =>
                  list.node.spaceCity === city.node.city &&
                  list.node.spaceType === "Conference Room"
              )
              if (localitynew.length > 0)
                return (
                  <div className="col-md-4 col-6">
                    <Link
                      to={
                        "/meeting-spaces/" +
                        city.node.slug +
                        "/all/?operatorName=" +
                        "Iksana"
                      }
                    >
                      <CityImage
                        name={city.node.city}
                        spaceType="Conference Room"
                      ></CityImage>
                      <h3
                        style={{
                          padding: "20px 0 0px",
                          fontWeight: "bold",
                          color: "#303030",
                          lineHeight: "1",
                          fontSize: "27px",
                        }}
                      >
                        {city.node.city}
                      </h3>
                      <h4
                        style={{
                          fontWeight: "900",
                          color: "#303030",
                          fontSize: "16px",
                        }}
                      >
                        {localitynew.length} options
                      </h4>
                      <br />
                    </Link>
                  </div>
                )
            })}
          </div>
        </div>

        <div className="container">
          <br></br>
          <div className="row">
            <div className="col-md-12">
              <h2
                style={{
                  padding: "20px 0 0px",
                  fontWeight: "bold",
                  color: "#303030",
                  lineHeight: "1",
                  fontSize: "32px",
                }}
              >
                Coworking Day Passes
              </h2>
            </div>
          </div>
          <div className="row">
            {data.allCities.edges.map(city => {
              const localitynew = data.allListings.edges.filter(
                list =>
                  list.node.spaceCity === city.node.city &&
                  list.node.spaceType === "Shared Workspace"
              )
              if (localitynew.length > 0)
                return (
                  <div className="col-md-4 col-6">
                    <Link
                      to={
                        "/coworking-spaces/" +
                        city.node.slug +
                        "/all/?operatorName=" +
                        "Iksana"
                      }
                    >
                      <CityImage
                        name={city.node.city}
                        spaceType="Shared Workspace"
                      ></CityImage>
                      <h3
                        style={{
                          padding: "20px 0 0px",
                          fontWeight: "bold",
                          color: "#303030",
                          lineHeight: "1",
                          fontSize: "27px",
                        }}
                      >
                        {city.node.city}
                      </h3>
                      <h4
                        style={{
                          fontWeight: "900",
                          color: "#303030",
                          fontSize: "16px",
                        }}
                      >
                        {localitynew.length} options
                      </h4>
                      <br />
                    </Link>
                  </div>
                )
            })}
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-12">
              <p className="" style={{ fontSize: "18px" }}>
                What sets iKSANA Workspaces apart?
              </p>

              <ul style={{ fontSize: "18px" }}>
                <li>
                  Flexible Workspace Solutions: From hot desks for on-the-go
                  hustlers to private offices for established teams, iKSANA
                  offers a spectrum of options to suit your needs.
                </li>
                <li>
                  Thriving Community: More than just a workspace, iKSANA fosters
                  a collaborative environment where freelancers, startups, and
                  enterprises can connect, share ideas, and ignite innovation.
                </li>
                <li>
                  Premium Amenities: Fuel your productivity with high-speed
                  Wi-Fi, modern meeting rooms, and a range of top-notch
                  facilities designed to support your success
                </li>
              </ul>
              <p>
                iKSANA isn't just a workspace, it's a springboard for your
                ambitions.
              </p>
              <br />

              <br />
              <b style={{ fontSize: "18px", fontWeight: "bold" }}>
                Who is GoFloaters?
              </b>
              <p style={{ fontSize: "17px" }}>
                GoFloaters is a leading coworking space aggregator with a
                network spanning 49 cities and 300 + operators. They offer
                solutions ranging from hybrid workplace platform, meeting spaces
                and coworking offices. iKSANA’s booking website is powered by
                GoFloaters technology platform to make your booking experience
                seamless and simple
              </p>
              <br />
            </div>
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}
